import type { FireMissionsResponse } from ".";

export const customChartsData: FireMissionsResponse[] = [
    {
        baseLine: null,
        xAxisInterval: 0,
        yAxisInterval: 0,
        datasets: [
            {
                param1: 1,
                param2: "8km",
                param3: "5",
                param4: "58",
                param5: "60",
                param6: "72",
                param7: "62",
                param8: "0",
                param9: "Firing more than one minute after receiving the order to fire.",
            },
            {
                param1: 2,
                param2: "9km",
                param3: "9",
                param4: "64",
                param5: "52",
                param6: "64",
                param7: "73",
                param8: "0",
                param9: "Firing prior to receiving the order to fire.",
            },
            {
                param1: 3,
                param2: "7km",
                param3: "7",
                param4: "78",
                param5: "45",
                param6: "43",
                param7: "49",
                param8: "64",
                param9: "Comment for mission 3.",
            },
            {
                param1: 4,
                param2: "11km",
                param3: "4",
                param4: "55",
                param5: "89",
                param6: "61",
                param7: "57",
                param8: "57",
                param9: "Comment for mission 4.",
            },
            {
                param1: 5,
                param2: "6km",
                param3: "5",
                param4: "89",
                param5: "62",
                param6: "90",
                param7: "42",
                param8: "60",
                param9: "Comment for mission 5.",
            },
            {
                param1: 6,
                param2: "12km",
                param3: "6",
                param4: "58",
                param5: "66",
                param6: "66",
                param7: "66",
                param8: "60",
                param9: "Comment for mission 6.",
            },
            {
                param1: 7,
                param2: "13km",
                param3: "7",
                param4: "58",
                param5: "77",
                param6: "77",
                param7: "77",
                param8: "70",
                param9: "Comment for mission 7.",
            },
        ],
        gridView: null,
        legend: {
            items: [
                {
                    name: "FIRE MISSION",
                },
                {
                    name: "DISTANCE TRAVELED",
                },
                {
                    name: "ROUNDS FIRED",
                },
                {
                    name: "PHASE 1 SCORE",
                },
                {
                    name: "PHASE 2 SCORE",
                },
                {
                    name: "PHASE 3 SCORE",
                },
                {
                    name: "PHASE 4 SCORE",
                },
                {
                    name: "OVERALL SCORE",
                },
                {
                    name: "COMMENTS",
                },
            ],
        },
        label: "Fire Missions",
        value: null,
        chartType: "DataTable",
        code: "session-fire-mission-table",
        description: "returns the fire mission table for a session.",
    },
];

export const customChartsDataEmpty: FireMissionsResponse[] = [
    {
        baseLine: null,
        xAxisInterval: 0,
        yAxisInterval: 0,
        datasets: [],
        gridView: null,
        legend: null,
        label: "Fire Missions",
        value: null,
        chartType: "DataTable",
        code: "session-fire-mission-table",
        description: "returns the fire mission table for a session.",
    },
];
