import React from "react";

import {
    ScrollableTableBody,
    StyledCell,
    StyledHead,
    StyledHeader,
    StyledRow,
    StyledTable,
    TableContainer,
} from "./data-table.styles";

interface DataTableProps {
    tableData: any[] | null;
    tableHeaders: any[] | null;
    selectedItems: any[] | null;
}

const DataTable = ({ tableData, tableHeaders, selectedItems }: DataTableProps) => {
    return (
        <TableContainer>
            <StyledTable style={{ whiteSpace: "nowrap" }}>
                <StyledHead>
                    <tr>
                        {tableHeaders?.map((header: any) => {
                            return <StyledHeader key={header.name}>{header.name}</StyledHeader>;
                        })}
                    </tr>
                </StyledHead>
                <ScrollableTableBody>
                    {selectedItems?.map((listItem) => {
                        const individualItem = tableData?.find(
                            (item: any) => item.param1 === listItem.id,
                        );

                        if (!individualItem) return null;

                        return (
                            <StyledRow key={listItem.id}>
                                {Object.values(individualItem).map(
                                    (parameter: any, index: number) => (
                                        <StyledCell key={`${parameter}-${index}`}>
                                            {parameter}
                                        </StyledCell>
                                    ),
                                )}
                            </StyledRow>
                        );
                    })}
                </ScrollableTableBody>
            </StyledTable>
        </TableContainer>
    );
};

export default DataTable;
