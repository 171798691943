import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

// import { useSessionDetailsContext } from "@/context/session-details/session-details";

import ContentLoader from "@/components/content-loader/content-loader";
import EmptyState from "@/components/empty-state/empty-state";
import { EmptyStateType } from "@/components/empty-state/empty-state.types";
import { MultiSelectDropdown } from "@/components/filters/multi-select/multi-select";
import type { MultiSelectOption } from "@/components/filters/multi-select/multi-select.types";
import { TargetIcon20 } from "@/components/icons";
import DataTable from "@/components/ui/data-table/data-table";

import { DropdownFilterVariant } from "@/types/filters";

import { Title } from "../../session-detail.styles";

import { customChartsData } from "./mockData";

import { Container, HeaderContainer } from "./fire-mission.styles";

export interface TableHeaders {
    name: string;
}

export interface FireMissionsDataSet {
    [key: string]: string | number;
}
export interface FireMissionsResponse {
    baseLine: number | null;
    xAxisInterval: number;
    yAxisInterval: number;
    datasets: FireMissionsDataSet[] | [];
    gridView: any | null;
    legend: { items: TableHeaders[] } | null;
    label: string | null;
    value: any | null;
    chartType: string;
    code: string;
    description: string;
}

export const FireMissions = ({ ...remainingProps }) => {
    //TODO: still to integrate with BE
    // const { customChartsData } = useSessionDetailsContext();

    const [selectedItems, setSelectedItems] = useState<MultiSelectOption[]>([]);

    const fireMissionsApiResponse = customChartsData?.find((data: any) => {
        return data.chartType === "DataTable";
    });

    const fireMissionsData: FireMissionsDataSet[] = fireMissionsApiResponse?.datasets || [];
    const fireMissionsTableHeaders: TableHeaders[] = fireMissionsApiResponse?.legend?.items || [];
    const tableTitle: string = fireMissionsApiResponse?.label || "";

    //TODO: still to integrate with BE
    const isLoading = false;
    const isError = false;

    const hasData = fireMissionsData?.length > 0 && !isError;
    const isDataEmpty = !isError && !hasData;

    //initialize all missions as selected by default
    useEffect(() => {
        const initialSelections = fireMissionsData?.map((mission: any) => ({
            label: `Mission ${mission.param1}`,
            id: mission.param1,
        }));
        setSelectedItems(initialSelections);
    }, [fireMissionsData]);

    const handleSelectionChange = (selectedItems: MultiSelectOption[]) => {
        setSelectedItems(selectedItems);
    };

    if (isLoading) {
        return <ContentLoader height="22.625rem" />;
    }

    return (
        <Container {...remainingProps}>
            <HeaderContainer>
                <Title>
                    {tableTitle ? (
                        tableTitle
                    ) : (
                        <FormattedMessage id={"session-details:fire-missions-table:title"} />
                    )}
                </Title>
                {hasData && (
                    <MultiSelectDropdown
                        variant={DropdownFilterVariant.Secondary}
                        options={fireMissionsData?.map((mission: any) => ({
                            label: `Mission ${mission.param1}`,
                            id: mission.param1,
                        }))}
                        initialSelectedOptions={selectedItems}
                        title={tableTitle}
                        showSelectAll
                        popUpWidth="16.125rem"
                        icon={<TargetIcon20 />}
                        handleOnClick={() => {}}
                        onSelectionChange={(selectedItems) => handleSelectionChange(selectedItems)}
                    />
                )}
            </HeaderContainer>
            {hasData && (
                <DataTable
                    tableData={fireMissionsData}
                    tableHeaders={fireMissionsTableHeaders}
                    selectedItems={selectedItems}
                />
            )}
            {isDataEmpty && (
                <EmptyState
                    emptyStateType={EmptyStateType.Charts}
                    title="session-details:fire-missions-table:empty-state:title"
                    minHeight="16.5rem"
                />
            )}

            {isError && (
                <EmptyState
                    emptyStateType={EmptyStateType.Error}
                    title="session-details:fire-missions-table:error-state:title"
                    description="session-details:fire-missions-table:error-state:description"
                    minHeight="16.5rem"
                />
            )}
        </Container>
    );
};
