import styled, { css } from "styled-components";

import base from "@/themes/base/base";

import { DropdownFilterVariant } from "@/types/filters";

import { Item } from "../filters.styles";

export const TitleAndCountWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

export const SelectedCountSpan = styled.span`
    border-radius: 1.5rem;
    background-color: ${base.colors.primary.orange[500]};
    padding: 0 0.5rem;
    color: ${base.colors.neutral.grey[900]};
`;

export const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
    display: flex;
    transform: ${({ isOpen }) => (isOpen ? "scaleY(-1)" : "scaleY(1)")};
    transition: transform 0.3s ease;
`;

export interface SelectAllContainerStylingProps {
    isChecked: boolean;
    variant: DropdownFilterVariant;
}

export const SelectAllContainer = styled.div<SelectAllContainerStylingProps>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    color: ${base.colors.neutral.grey[50]};
    height: 2.5rem;
    box-sizing: border-box;
    position: relative;

    ${({ variant, isChecked }) => {
        if (variant === DropdownFilterVariant.Primary) {
            return css`
                ${isChecked ? base.typography.smallMedium : base.typography.smallRegular};
            `;
        }

        if (variant === DropdownFilterVariant.Secondary) {
            return css`
                ${isChecked ? base.typography.extraSmallMedium : base.typography.extraSmallRegular};
            `;
        }
    }}

    &:hover {
        background-color: ${base.colors.neutral.grey[600]};

        .styled-checkbox {
            border-color: ${base.colors.primary.orange[500]};
            background-color: ${base.colors.neutral.grey[600]};

            &.checked {
                border-color: ${base.colors.primary.orange[300]};
                background-color: ${base.colors.primary.orange[300]};
            }
        }
    }
`;

interface StyledItemStylingProps {
    isChecked: boolean;
    variant: DropdownFilterVariant;
}

export const StyledItem = styled(Item)<StyledItemStylingProps>`
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem 0.5rem 0.75rem;

    ${({ variant, isChecked }) => {
        if (variant === DropdownFilterVariant.Primary) {
            return css`
                ${isChecked ? base.typography.smallMedium : base.typography.smallRegular};
            `;
        }

        if (variant === DropdownFilterVariant.Secondary) {
            return css`
                ${isChecked ? base.typography.extraSmallMedium : base.typography.extraSmallRegular};
            `;
        }
    }}

    &:hover {
        background-color: ${base.colors.neutral.grey[600]};

        .styled-checkbox {
            border-color: ${base.colors.primary.orange[500]};
            background-color: ${base.colors.neutral.grey[600]};

            &.checked {
                border-color: ${base.colors.primary.orange[300]};
                background-color: ${base.colors.primary.orange[300]};
            }
        }
    }
`;

export const SeparatorLine = styled.div`
    width: 100%;
    background-color: ${base.colors.neutral.grey[900]};
    height: 0.0625rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
`;

export const TextSpan = styled.span`
    color: ${base.colors.neutral.grey[50]};
`;
