import { useCallback, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FormattedMessage } from "react-intl";

import { FormControlLabel, Radio } from "@mui/material";

import { CustomIcon } from "@/pages/onboarding/survey/survey.styles";

import { Container, List, ListContainer, PopUp, Toggle } from "@/components/filters/filters.styles";
import { Checkbox } from "@/components/filters/multi-select/components/checkbox/checkbox";
import { CheckboxContainer } from "@/components/filters/multi-select/components/checkbox/checkbox.styles";
import {
    ArrowIconContainer,
    SelectedCountSpan,
    StyledItem,
    TextSpan,
} from "@/components/filters/multi-select/multi-select.styles";
import { ChevronDownSquareCornersIcon24 } from "@/components/icons";

import { DropdownFilterVariant } from "@/types/filters";

import { RoleOptions, StatusOptions } from "@/enums/user";

import {
    Divider,
    FilterCheckedIcon,
    FilterRadioButton,
    GroupName,
    RoleContainer,
    StatusContainer,
} from "./users-filter.styles";

export type Option = {
    id: string;
    label: string;
    value?: string | null;
};

type Props = {
    onRoleChange: (selected: Option[]) => void;
    onStatusChange: (status: string) => void;
    style?: React.CSSProperties;
    title: string;
    icon: React.ReactElement;
    showSelectAll?: boolean;
    variant: DropdownFilterVariant;
};

export const UserMultiSelectDropdown = ({
    onRoleChange,
    onStatusChange,
    title,
    icon,
    variant = DropdownFilterVariant.Primary,
    ...props
}: Props) => {
    const [selectedRoles, setSelectedRoles] = useState<Option[]>(RoleOptions);
    const [selectedStatus, setSelectedStatus] = useState<string | null>();
    const [isOpen, setIsOpen] = useState(false);
    const filterLength = selectedRoles.length + 1;

    useEffect(() => {
        if (selectedStatus) {
            onStatusChange(selectedStatus);
        }
    }, [selectedStatus]);

    const handleToggleList = useCallback(() => {
        setIsOpen((currentIsOpen) => !currentIsOpen);
    }, []);

    const handleOptionToggle = useCallback(
        (option: Option) => {
            setSelectedRoles((prevSelected) => {
                const isSelected = prevSelected.some(({ id }) => id === option.id);
                const newSelected = isSelected
                    ? prevSelected.filter(({ id }) => id !== option.id)
                    : [...prevSelected, option];
                onRoleChange(newSelected);

                return newSelected;
            });
        },
        [onRoleChange],
    );

    const clickOutsideRef = useDetectClickOutside({
        onTriggered: () => {
            setIsOpen(false);
        },
    });

    const CustomRadio = (
        <Radio checkedIcon={<FilterCheckedIcon />} icon={<CustomIcon className="radio-icon" />} />
    );

    return (
        <Container ref={clickOutsideRef} {...props}>
            <Toggle isOpen={isOpen} onClick={handleToggleList} variant={variant}>
                {icon}
                <span>{title}</span>
                <SelectedCountSpan>{filterLength}</SelectedCountSpan>
                <ArrowIconContainer isOpen={isOpen}>
                    <ChevronDownSquareCornersIcon24 />
                </ArrowIconContainer>
            </Toggle>

            {isOpen && (
                <PopUp popUpWidth="16.125rem">
                    <ListContainer variant={variant}>
                        <RoleContainer>
                            <GroupName>
                                <FormattedMessage id="users:filter:role" />
                            </GroupName>
                            <Divider />
                            <List>
                                {RoleOptions.map((option: Option) => (
                                    <StyledItem
                                        key={option.id}
                                        variant={variant}
                                        isChecked={selectedRoles.some(({ id }) => id === option.id)}
                                        onClick={() => handleOptionToggle(option)}
                                    >
                                        <CheckboxContainer>
                                            <Checkbox
                                                checked={selectedRoles.some(
                                                    ({ id }) => id === option.id,
                                                )}
                                                onChange={() => handleOptionToggle(option)}
                                            />
                                        </CheckboxContainer>
                                        <TextSpan
                                            className={
                                                selectedRoles.some(({ id }) => id === option.id)
                                                    ? "checked"
                                                    : ""
                                            }
                                        >
                                            {option.label}
                                        </TextSpan>
                                    </StyledItem>
                                ))}
                            </List>
                        </RoleContainer>

                        <StatusContainer>
                            <GroupName>
                                <FormattedMessage id="users:filter:status" />
                            </GroupName>
                            <Divider />

                            <FilterRadioButton
                                defaultValue="all"
                                value={selectedStatus}
                                onChange={(event) => {
                                    setSelectedStatus(event.target.value || null);
                                }}
                            >
                                <CheckboxContainer>
                                    {StatusOptions.map((option: Option) => (
                                        <FormControlLabel
                                            key={option.id}
                                            value={option.value}
                                            control={CustomRadio}
                                            label={option.label}
                                        />
                                    ))}
                                </CheckboxContainer>
                            </FilterRadioButton>
                        </StatusContainer>
                    </ListContainer>
                </PopUp>
            )}
        </Container>
    );
};
